import { useEffect } from 'react';
import { useFetcher } from '@remix-run/react';
import { useToaster } from '~/modules/root/context/ToasterContext';
import {
    getContentEditorLink,
    getRepositionThumbLink,
} from '~/modules/root/util/item-link-helpers';
import { fetcherIsDone } from '~/utils/fetcherHelper';
import { DELETE_DIRECTORY_ITEM } from '~/routes/remix-api.directory.delete-directory-item';
import type { action as deleteAction } from '~/routes/remix-api.directory.delete-directory-item';
import { useToggleHomePin } from '~/modules/home/hook/use-toggle-home-pin';
import { useAppData } from '~/modules/root/hook/use-app-data';

interface UseTileAdminActionsProps {
    item: {
        id: string;
        link: string;
        professionalLink: string;
        pinned?: boolean;
    };
    itemType: 'products' | 'projects' | 'professionals' | 'articles';
}

export const useTileAdminActions = (input: UseTileAdminActionsProps) => {
    const { item, itemType } = input;
    const deleteFetcher = useFetcher<typeof deleteAction>();
    const { toast } = useToaster();
    const { rootGrants } = useAppData();
    const canEditAdmin = rootGrants.editAdvertAdmin;

    const { toggleHomePin } = useToggleHomePin(canEditAdmin, (error) => {
        if (error.Message) {
            toast(error.Message, { type: 'error' });
        }
    });

    useEffect(() => {
        if (
            fetcherIsDone(deleteFetcher) &&
            deleteFetcher.data.deleteDirectoryItem === true
        ) {
            toast(
                `${itemType.charAt(0).toUpperCase() + itemType.slice(1)} deleted, please allow a few minutes for results to be updated.`,
                { type: 'success' }
            );
        }
    }, [deleteFetcher, toast, itemType]);

    const onEdit = () => {
        const url = getContentEditorLink(
            itemType,
            item.link,
            item.professionalLink
        );
        if (url && typeof window !== 'undefined') {
            window.open(url, '_blank')?.focus();
        }
    };

    const onRepositionImage = () => {
        const url = getRepositionThumbLink(
            itemType,
            item.link,
            item.professionalLink
        );
        if (url && typeof window !== 'undefined') {
            window.open(url, '_blank')?.focus();
        }
    };

    // disable delete for professionals
    const onDelete =
        itemType === 'professionals'
            ? undefined
            : () => {
                  deleteFetcher.submit(
                      {
                          ID: item.id.toString(),
                      },
                      {
                          method: 'post',
                          action: DELETE_DIRECTORY_ITEM,
                      }
                  );
              };

    const onTogglePin = () => {
        toggleHomePin(Number(item.id), !item.pinned);
    };

    return {
        onEdit,
        onRepositionImage,
        onDelete,
        onTogglePin,
    };
};
