import type {
    TileableModel,
    TileViewModel,
} from '@rocco/ui/tile-shared/view-models/tile';
import { Tile } from '@rocco/ui/tile-shared/components/Tile';
import { useTileAdminActions } from '@modules-rocco/admin/hooks/use-tile-admin-actions';
import { isProfessionalTileViewModel } from '@rocco/ui/tile-professional/view-models/professional';
import { isProjectTileViewModel } from '@rocco/ui/tile-project/view-models/project';
import { isProductTileViewModel } from '@rocco/ui/tile-product/view-models/product';
import { useLogImpression } from '@archipro-website/tracker';
import { useRef } from 'react';

/**
 * Props interface for the CategoryPageTileWithAdminActions component
 * @interface CategoryPageTileWithAdminActionsProps
 * @property {TileViewModel<TileableModel>} tile - The tile view model to render with admin actions
 */
interface CategoryPageTileWithAdminActionsProps {
    tile: TileViewModel<TileableModel>;
}

/**
 * Component that renders a tile with admin actions if applicable
 * Wraps the base Tile component and adds admin functionality for authorized users
 * Handles different item types (professionals, products, projects) with appropriate links
 * @param {CategoryPageTileWithAdminActionsProps} props - Component props
 * @returns {JSX.Element} The rendered tile with admin actions
 */
export const CategoryPageTileWithAdminActions = (
    props: CategoryPageTileWithAdminActionsProps
) => {
    const { tile } = props;
    const tileRef = useRef<HTMLDivElement>(null);

    const model = tile.model;
    const adminActions = useTileAdminActions({
        item: {
            id: model.id.toString(),
            link: 'link' in model ? model.link : '',
            professionalLink: getProfessionalLink(model),
            pinned: tile.adminStatusInfo?.pinned,
        },
        itemType: getItemTypeLink(model),
    });

    useLogImpression({
        data: {
            type: 'LibraryItem_Directory',
            itemID: model.id,
            professionalID: getProfessionalId(model),
        },
        ref: tileRef,
    });

    return (
        <div ref={tileRef}>
            <Tile
                tile={tile}
                adminActions={tile.adminStatusInfo ? adminActions : undefined}
            />
        </div>
    );
};

/**
 * Helper function to get the professional link from a tile model
 * Handles different model types that may contain professional links
 * @param {TileableModel} model - The tile model to extract professional link from
 * @returns {string} The professional link or empty string if not found
 */
const getProfessionalLink = (model: TileableModel): string => {
    if (isProfessionalTileViewModel(model)) {
        return model.link;
    }

    if (isProductTileViewModel(model)) {
        return model.professional?.link ?? '';
    }

    if (isProjectTileViewModel(model)) {
        return model.professional?.link ?? '';
    }

    return '';
};

const getProfessionalId = (model: TileableModel): number => {
    if (isProfessionalTileViewModel(model)) {
        return model.id;
    }

    if (isProductTileViewModel(model)) {
        return model.professional.id;
    }

    if (isProjectTileViewModel(model)) {
        return model.professional.id;
    }

    return 0;
};

/**
 * Helper function to determine the item type for admin actions
 * Maps different model types to their corresponding admin section
 * @param {TileableModel} model - The tile model to determine type for
 * @returns {string} The item type identifier for admin actions
 */
const getItemTypeLink = (model: TileableModel) => {
    if (isProfessionalTileViewModel(model)) {
        return 'professionals';
    }

    if (isProductTileViewModel(model)) {
        return 'products';
    }

    return 'projects';
};
